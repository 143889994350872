/* You can add global styles to this file, and also import other style files */

* { font-family: 'Roboto', sans-serif; }
a { color: #349980 }
.bg-green a { color: #fff }
article { position: relative; padding: 3rem 1rem 1rem 1.5rem; margin: 2rem 0; }
article.bg-green { margin: 4rem 0; }
.bg-green { background-color: #349980; color: #fff; }
h2 { background: #000; color: #fff; font-size: 1.5rem; font-weight: 900; min-width: 50%; padding: 1rem 1.5rem; position: absolute; top: -2rem; margin: 0 0 5rem -1.5rem; clip-path: polygon(0 0, 100% 0, 97% 100%, 0 100%); }
h1 { color: #fff; font-size: 4.5rem; font-weight: 900 }
h2 { background: #000; color: #fff; font-size: 1.5rem; font-weight: 900 }
.hero { min-height: 89vh; background-size: cover; background-image: url('./assets/images/bg-home.jpg'); }
.hero h1, .hero h3 { color: #fff; text-shadow: 2px 2px 4px #000; }
.nav-item { font-weight: 900; color: #000 }
.nav-link { padding: .5rem 2rem!important; }
footer a { color: #fff!important }
footer a:hover { color:#349980!important }
